<template>
  <div class="h-100">
    <CCard class="h-100">
      <vue-element-loading
        :active="loading"
        :text="loadingText"
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
      <CRow class="p-3">
        <CCol>
          <CInput
            type="text"
            name="search"
            v-model="searchName"
            placeholder="عنوان فیلم خود را جستجو کنید..."
            class="searchBox mb-0"
          />
        </CCol>
      </CRow>
      <ul id="movies">
        <li
          v-for="(item, index) in movesList"
          :key="index"
          class="movie-item d-flex bg-white align-items-center gap-20 position-relative"
          @click="showModal(item.move)"
        >
          <img src="/img/guidance-bg.png" alt="" class="guidance-bg"/>
          <div class="position-relative">
            <video
              controls
              poster="/img/video-tutorial-poster.jpg"
              preload="none"
              :src="getSrc(item.move, 'HelpMove')"
            ></video>
            <CIcon name="cilPlay" class=""></CIcon>
          </div>
          <h4>
            <CIcon name="cilFile" class="ml-2"></CIcon>
            <span>{{ item.name }}</span>
          </h4>
        </li>
      </ul>
    </CCard>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {apiUrlRoot} from "../../constants/config";

export default {
  name: "helpMove",
  components: {
  },
  data() {
    return {
      movesList: null,
      loading: false,
      loadingText: "در حال دریافت اطلاعات...",
      searchName: null,
      moveItem: null,
    };
  },
  watch: {
    searchName: function () {
      this.fetchData();
    },
  },
  methods: {
    ...mapActions("helpMove", ["GetAll"]),
    getSrc(file) {
      return `${apiUrlRoot}/HelpMove/${file}`;
    },
    async fetchData() {
      this.loading = true;
      let result = await this.GetAll({name: this.searchName});
      this.loading = false;
      if (result) {
        this.movesList = result;
        if (result.length > 0) this.showModal(result[0].move);
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات <br /><br />",
          type: "error",
        });
      }
    },

    showModal(fileName) {
      this.moveItem = this.getSrc(fileName, "HelpMove");
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
#movies{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap:20px;
  padding: 10px 40px;
}

.movie-item {
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3 !important;
  padding: 20px 0;
  border-radius: var(--border-radius);
  box-shadow: 0 0 10px 0 #d3d3d3;
  overflow: hidden;
  justify-content: space-between;

}

.movie-item video {
  height: 250px;
  width: 100%;
}

.movie-item h4 {
  font-size: 15px;
  color: #000;
}

.guidance-bg {
  position: absolute;
  top: 0;
  filter: hue-rotate(176deg) opacity(0.1) blur(1px);
  width: 100%;
}
</style>
